import { Brick, CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";

export class ci_snapshot_webcomponent extends HTMLElement {
    ci: web_ci_snapshot | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_ci_snapshot(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('ci-snapshot', ci_snapshot_webcomponent);

export class web_ci_snapshot extends CodeBrick {

    element: HTMLElement;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {

        //console.log("c-snapshot "+this.blueprint.name+" cb_event "+input+" "+JSON.stringify(this.blueprint.ins)+" => "+JSON.stringify(cfg));
        
        if(input == "cfg")
        {
            let snapshot_name = cfg["snapshot_name"] || "Export";
            let container_brick_name = cfg["container_brick"];

            let container_brick = this.context.compositions[this.cid];
            let snapshot_composition = location.pathname.substring(1) || this.context.composition_runners[this.cid].compos?.system_options?.home_composition || "home"; //use this in the left for jit generators and slug scenario // container_brick.composition;
            snapshot_composition = snapshot_composition.split("?")[0];
            if(snapshot_composition == "editor") {
                snapshot_composition = container_brick.composition;
            }
            if(container_brick_name) {
                //container_brick = this.find_brick(this.context.compositions[this.cid], container_brick_name);     
                let b = this.find_brick(this.context.compositions[this.cid], container_brick_name);
                if(b.name != container_brick.name) {
                    //We keep the io, so the args will be in the snapshot
                    container_brick.contains = [b];
                }
            }

            if(container_brick) {
                let snapshots = {} as any;
                this.take_snapshots(snapshots, container_brick);

                let s = window.location.pathname.split("/");
                let slug = s[s.length-1];
                snapshots[container_brick.name].args = snapshots[container_brick.name].args || {};
                snapshots[container_brick.name].args.slug = slug;


                let snapshot_post_obj = {
                    snapshot_name: snapshot_name,
                    snapshot: snapshots,
                    container_brick_name: container_brick_name,
                    timezone_offset: this.context.timezone_offset
                } as any;

                if(cfg.base_snapshot_id) {
                    snapshot_post_obj.base_snapshot_id = cfg.base_snapshot_id;
                }

                let snapshot_type = "temp";
                if(cfg.snapshot_type == "Saved") {
                    snapshot_type = "saved";
                }

                if(this.context.composition_runners[this.cid].debugging_enabled) {
                    snapshot_post_obj.debugging_enabled = true;
                }
                
                let res = await this.api_request(snapshot_composition, snapshot_post_obj, snapshot_type);

                //console.log("ci-snapshot "+snapshot_composition+" "+JSON.stringify(snapshot_post_obj) +" res: "+JSON.stringify(res));
                res.passthrough_data = cfg.passthrough_data;

                return { "@": res };
            }
            else {
                console.error("c-snapshot: Container brick "+container_brick_name+" not found.");
            }
        }   
    }

    take_snapshots(snapshots: any, blueprint: Brick) {
        let ci = this.get_brick_by_name(blueprint.name);
        if(ci) {
            let ss = ci.cb_snapshot();
            if(ss && Object.keys(ss).length > 0) {
                snapshots[blueprint.name] = ss;
            }
        }
        if(blueprint.contains) {
            for(let b of blueprint.contains) {
                this.take_snapshots(snapshots, b);
            }
        }
    }

    get_brick_by_name(name: string) {
        //console.log("get_brick_by_name "+"cb"+this.cid+"_"+name);
        let ci = this.context.bricks["cb"+this.cid+"_"+name];
        //We do not take snapshots of dynamic bricks. So this wil return undefined for those bricks, that also have the dc in the brick_id
        return ci;
    }

    find_brick(at: any, name: string) : any {
        if(at.name == name) {
            return at;
        }
        if(at.contains) {
            for(let c of at.contains) {
                let b = this.find_brick(c, name);
                if(b) {
                    return b;
                }
            }
        }
        return null;
    }

    async api_request(composition_name: string, snapshot_post_obj: any, snapshot_type: string) : Promise<any> {

        this.emit_state("loading");

        let res_data: any;

        let method = "POST";

        let options = { method };

        let uri = "/cb_api/cb/"+snapshot_type+"_snapshot/"+composition_name;

        (<any>options).body = JSON.stringify(snapshot_post_obj);
        
        (<any>options).credentials = 'include';

        (<any>options).headers = { "Content-Type":"application/json" };

        let res = await fetch(uri, options);

        let body = await res.text();

        try {
            res_data = JSON.parse(body);
        }
        catch(err) {
            res_data = body;
        }
        this.emit_state("");
        return res_data;
    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {
    }
    cb_snapshot() {
        //Hey bro I herd you like snapshots
    }
}
