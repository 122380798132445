import { CBCompositionRunner } from "../../codebricks-runtime/CBCompositionRunner";
import { Brick, CBContext, CBEventInfo, Composition, DebugLog } from "../../codebricks-runtime/CBModels";
import { ObjectDeepMerge } from "../../codebricks-runtime/CBUtil";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { CBWebUtil } from "../controls/cb_web_util";
import { ExportFuncs } from "../../shared-funcs/ExportFuncs";

export class c_framed_composition_webcomponent extends HTMLElement {
    ci: web_c_framed_composition | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_c_framed_composition(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('c-jit-generator', c_framed_composition_webcomponent);

export class web_c_framed_composition extends CodeBrick {

    //shadowroot: ShadowRoot;
    element: HTMLElement;
    composition_id = '_';
    rtcn: CBCompositionRunner | undefined;

    cfg: any;

    compos: Composition | undefined;

    snapshot: any;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {

        //console.log("c-framed-composition "+this.brick_id+" cb_event "+input+" "+JSON.stringify(cfg));
        if(input == "cfg") {

            this.cfg = cfg;
            this.element.innerHTML = `<div></div>`;

            let self = this;

            let urlParams = this.getUrlParams();           

            if (cfg.generator && cfg.template) {
                let res = await fetch('/cb_api/generator/generate-jit/' + this.context.compositions[this.cid].composition + "." + this.blueprint.name + "?platform="+this.context.platform, {
                    mode: 'cors',
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json' 
                    },
                    body: JSON.stringify(cfg.params || {})
                }); 
                
                let body = await res.text(); 

                if(body === "") {
                    return; //We do not emit an empty resonse. This gives the server side a way no not emit. Used for request sequencing #rr
                }

                let gen_res = null as any;
                try {
                    gen_res = JSON.parse(body);
                }
                catch(err) {
                    console.error("c-jit-generator "+this.brick_id+": Error fetching composition json:"+ JSON.stringify(err));
                    return;
                }

                if(!gen_res.success) {
                    console.error("c-jit-generator "+this.brick_id+": Error fetching composition json:"+ JSON.stringify(gen_res));
                    return;
                }

                this.compos = gen_res.compos;

                if(!this.compos) {
                    return;
                }

                if(cfg.snapshot) {
                    this.snapshot = cfg.snapshot;

                    ExportFuncs.ApplyBrickSnapshots(this.compos as Brick, cfg.snapshot);
                }

                let context = (globalThis as any).codebricks_context;

                if(context) {
                    delete context.compositions[self.composition_id];
                }

                let composition = this.compos.composition;//this.context.compositions[this.cid].composition + "." + this.blueprint.name;

                let reuse_cid = null as string | null;
                if(!self.rtcn) {
                    self.rtcn = new CBCompositionRunner(composition as string, (<any>globalThis).codebricks_context,
                        async function(led: DebugLog) {
                            return await CBWebUtil.PostRequest("/cb_api/admin/debug_log/"+composition, led);
                        }
                    );
                    //self.rtcn.context_codebrick = self;
                }
                else {
                    reuse_cid = self.rtcn.cid;
                }

                if(self.rtcn.context.compositions._0.composition == "editor" && self.blueprint.name == "composition") {
                    self.rtcn.debugging_enabled = true; //for editor
                }
                //self.rtcn.edit_mode = true; //(self.cfg.edit_mode && self.cfg.edit_mode.enabled);

                this.compos.ins = this.compos.ins || {};


                //@args is deprecated
                //Also have to params availble as io.param, not just io.@args.param
                this.compos.ins.default = this.compos.ins.default || {};
                ObjectDeepMerge(this.compos.ins.default, urlParams);

                self.rtcn.init_composition(this.compos, urlParams, reuse_cid);

                if(cfg.params) {
                    ObjectDeepMerge(this.compos.ins, cfg.params);
                }
                this.compos.ins.args = this.compos.ins.args || {};
                ObjectDeepMerge(this.compos.ins.args, urlParams);

                let slot = self.element.querySelector('div');
                if(slot) {
                    slot.innerHTML = `<${this.compos.type} cid="${self.rtcn.cid}" name="${this.compos.name}"></${this.compos.type}>`;
                }

                if(window.localStorage.getItem("cb_locale")) {
                    this.compos.system_options.locale = window.localStorage.getItem("cb_locale");
                }
                
                let result = await self.rtcn.send_initialisation_events(null);

                //console.log("c-framed-composition "+self.brick_id+":"+self.cfg.composition+" loaded, result "+JSON.stringify(result));

                //self.cb_emit({ "@" : result, "@loaded": true });
                result["@loaded"] = true;
                //await self.cb_emit(result);

                self.rtcn.context_codebrick = self;

                return result;
                                
            }
        }
        else if(input == "take_snapshot") {
            return { "@snapshot": this.cb_snapshot().cfg.snapshot };
        }
    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {
    }

    cb_snapshot() {
        let snapshots = {};
        
        this.take_snapshots(snapshots, this.compos as Brick);

        return { cfg: { snapshot: ObjectDeepMerge(this.snapshot, snapshots) } };
    }

    take_snapshots(snapshots: any, blueprint: Brick) {

        if(!blueprint) {
            console.error("blueprint "+blueprint);
            return;
        }

        let ci = this.get_brick_by_name(blueprint.name);
        if(ci) {
            let ss = ci.cb_snapshot();
            if(ss && Object.keys(ss).length > 0) {
                snapshots[blueprint.name] = ss;
            }
        }
        if(blueprint.contains) {
            for(let b of blueprint.contains) {
                this.take_snapshots(snapshots, b);
            }
        }
    }

    get_brick_by_name(name: string) {
        //console.log("get_brick_by_name "+"cb"+this.cid+"_"+name);
        let ci = this.context.bricks["cb"+this.rtcn?.cid+"_"+name];
        //We do not take snapshots of dynamic bricks. So this wil return undefined for those bricks, that also have the dc in the brick_id
        return ci;
    }

    getUrlParams() {
        let urlParams = {} as { [key:string]: string };
        let match,
        pl = /\+/g,  // Regex for replacing addition symbol with a space
        search = /([^&=]+)=?([^&]*)/g,
        decode = function (s: string) {
            return decodeURIComponent(s.replace(pl, " "));
        },
        query = window.location.search.substring(1);

        while (match = search.exec(query)) {
            if (decode(match[1]) in urlParams) {
                if (!Array.isArray(urlParams[decode(match[1])])) {
                    (<any>urlParams)[decode(match[1])] = [urlParams[decode(match[1])]];
                }
                (<any>urlParams[decode(match[1])]).push(decode(match[2]));
            } else {
                urlParams[decode(match[1])] = decode(match[2]);
            }
        }
        return urlParams;
    }

    async cb_unload() {
        //console.log(this.brick_id+" UNLOAD ");

        if(!this.context.bricks[this.brick_id]) {
            return;
        }

        if(this.context.bricks && this.rtcn) {
            let pf = "cb"+this.rtcn.cid+"_";
            let keys = Object.keys(this.context.bricks);
            for(let k = keys.length - 1; k >= 0; k--) {
                let b = keys[k];
                if(this.context.bricks[b] && b.startsWith(pf)) {
                    //console.log(this.brick_id+" UNLOAD "+b);
                    if(b != this.brick_id) {
                        this.context.bricks[b].cb_unload();
                    }
                    delete this.context.bricks[b];
                }
            }
        }
        
    }

}

