import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { cb_popout } from "../controls/cb_popout";
import { Tokenfield } from "../../shared-funcs/Tokenfield"
import { CBWebUtil } from "../controls/cb_web_util";
import { drill } from "../../codebricks-runtime/CBUtil";

export class cc_tokenfield_webcomponent extends HTMLElement {
    ci: web_cc_tokenfield | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_cc_tokenfield(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('cc-tokenfield', cc_tokenfield_webcomponent);

export class web_cc_tokenfield extends CodeBrick {

    element: HTMLElement;
    initialised = false;
    value = "";
    label = "";
    placeholder = "";

    validation = null as any;

    popout: cb_popout;

    cfg: any;
    
    value_tokens = [] as string[];
    label_tokens = [] as string[];
    values = [] as any[];

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;

        this.element.innerHTML = `<div id="${this.brick_id}" class='cc-tokenfield-container no_click_close'></div>`;

        let self = this;

        this.popout = new cb_popout(this.brick_id, true, this.blueprint.ins.cfg, "cc-tokenfield", true, false,
        function() {
            //pop opened
            let input = document.getElementById(self.brick_id+"$input");
            if(input) {
                input.focus();              
            }

            self.cb_emit({"@popout_opened": true});

            let select = document.getElementById(self.brick_id+"$select");
            if(select) {
                select.classList.add("cc-tokenfield-select-open");
            }
            
        },
        function() {
            //pop closed
            let select = document.getElementById(self.brick_id+"$select");
            if(select) {
                select.classList.remove("cc-tokenfield-select-open");
            }
            let input = document.getElementById(self.brick_id+"$input") as HTMLInputElement;
            if(input) {
                input.classList.remove("cc-tokenfield-validation-failed");
                input.value = "";
            }
            let validation_message_container = document.getElementById(`${self.brick_id}&validation`);
            if(validation_message_container) {
                validation_message_container.innerHTML = "";
                validation_message_container.style.display = "none";
            }
        });

        this.popout.init();
                
        let edit_html = `<div class="cc-tokenfield-edit-container" id="${this.brick_id}$popout"><input type="text" class="c-input cc-tokenfield-input" id="${this.brick_id}$input" /> <button class="c-button cc-tokenfield-add-button" id="${this.brick_id}$add">Add</button></div><div class="cc-tokenfield-validation-message" id="${this.brick_id}&validation" style="display:none"></div>`;
        
        this.popout.setDropHtml(edit_html);

        this.init_cement();
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {
        
        // if(this.blueprint.name == "these_managers") {
        //     console.log(this.blueprint.type+" "+this.blueprint.name+" cb_event "+input+" "+JSON.stringify(cfg));
        // }

        if(input == "cfg") {
            this.cfg = cfg as any;
            this.validation = cfg.validation;
            if(!this.initialised) {

                this.initialised = true;

                let self = this;
                let add = document.getElementById(this.brick_id+"$add");
                if(add) {
                    add.addEventListener("click", function() {
                        self.add();
                    });
                }

                let input = document.getElementById(self.brick_id+"$input") as HTMLInputElement;
                if(input) {
                    input.addEventListener("keyup", function(e: any) {
                        if (e.key === 'Enter' || e.keyCode === 13) {
                            self.add();
                        }
                    });
                }
            }   

            this.setValue(cfg.value, false);

            let select = document.getElementById(this.brick_id+"$select") as HTMLInputElement;
            if(select) {
                if(cfg.disabled) {
                    select.classList.add("cc-tokenfield-disabled");
                    this.popout.disabled = true;
                }
                else {
                    select.classList.remove("cc-tokenfield-disabled");
                    this.popout.disabled = false;
                }
            }

            return { "@" : this.value_tokens, "@string": this.value_tokens.join(this.cfg.output_separator || ","), "@option_objects" : cfg.value }
        }
        else if(input == "add") {
            this.add_token(cfg);
            //this.popout.closeSelect();
            //this.popout.openPopout(this.popout); no this opens it even if its closed
        }
        else if(input == "close_popout") {
            this.popout.closeSelect();
        }
    }

    add() {
        let input = document.getElementById(this.brick_id+"$input") as HTMLInputElement;
        if(input) {
            let value = input.value;
            if(value != "") {
                if(this.add_token(value)) {
                    input.value = "";
                    this.popout.closeSelect();
                }
            }
        }
    }

    // add_token(value: string) {
    //     if(value != "" && this.cfg) {
    //         let value_tokens = Tokenfield.getTokens(value, this.cfg.separators, this.cfg.value_path);
    //         for(let token of value_tokens) {
    //             if(!this.tokens.includes(token)) {
    //                 this.tokens.push(token);
    //             }
    //         }
    //         this.setValue(this.tokens, true);
    //     }
    // }
 
    add_token(value: any) : boolean {

        //console.log("add "+JSON.stringify(value));
        
        if(typeof value == "string" && value != "" && this.cfg) {
            let value_tokens = Tokenfield.getTokens(value, this.cfg.separators, this.cfg.value_path);
            
            for(let token of value_tokens) {
                if(!this.value_tokens.includes(token)) {
                    if(this.validation && this.validation.pattern && !new RegExp('^(?:' + this.validation.pattern.trim() + ')$').test( token )) {
                        //validates = false;
                        //validation_message = this.validation.pattern_message;
                        let input = document.getElementById(this.brick_id+"$input") as HTMLInputElement;
                        if(input) {
                            input.classList.add("cc-tokenfield-validation-failed");
                        }
                        let validation_message_container = document.getElementById(`${this.brick_id}&validation`);
                        if(validation_message_container) {
                            validation_message_container.innerHTML = this.validation.pattern_message;
                            validation_message_container.style.display = "block";
                        }
                        return false;
                    }
                    this.value_tokens.push(token);
                }
            }
            this.label_tokens = value_tokens;
            this.setValue(this.value_tokens, true);
            let input = document.getElementById(this.brick_id+"$input") as HTMLInputElement;
            if(input) {
                input.classList.remove("cc-tokenfield-validation-failed");
            }
            let validation_message_container = document.getElementById(`${this.brick_id}&validation`);
            if(validation_message_container) {
                validation_message_container.innerHTML = "";
                validation_message_container.style.display = "none";
            }
        }
        else if(typeof value == "object" && value && this.cfg) {

            let str_value = drill(this.cfg.label_path || this.cfg.value_path, value);
            if(str_value != "") {

                let has = false;
                for(let has_value of this.values) {
                    if(JSON.stringify(has_value) == JSON.stringify(value)) {
                        has = true;
                        break;
                    }            
                }
                if(!has) {
                    this.values.push(value);
                }
                else {
                    return true;
                }
                
                // let value_tokens = Tokenfield.getTokens(this.values, this.cfg.separators, this.cfg.value_path);
                // let label_tokens = Tokenfield.getTokens(value, this.cfg.separators, this.cfg.label_path || this.cfg.value_path);
                for(let token of this.value_tokens) {
                    if(!this.value_tokens.includes(token)) {
                        this.value_tokens.push(token);
                    }
                }
                for(let token of  this.label_tokens) {
                    if(!this.label_tokens.includes(token)) {
                        this.label_tokens.push(token);
                    }
                }

                this.setValue(this.values, true);
            }

        }
        return true;
    }

    setValue(value: any, emit: boolean) {

        //console.log("setValue "+JSON.stringify(value)+" emit "+emit);

        if(Array.isArray(value)) {
            this.values = value;
        }
        else if(typeof value == "string") {
            this.values  = CBWebUtil.SplitByMultipleSeparators(value, this.cfg.separators || [","]);
        }
        this.value_tokens = Tokenfield.getTokens(this.values, this.cfg.separators, this.cfg.value_path);
        this.label_tokens = Tokenfield.getTokens(this.values, this.cfg.separators, this.cfg.label_path || this.cfg.value_path);

        let html = "";
        if(this.label_tokens) {
            let i = 0;
            for(let token of this.label_tokens) {
                if(token) {
                    html += `<div class="cc-tokenfield-token">${token}<div class="cc-tokenfield-token-close" id="${this.brick_id}$close$${i}"></div></div>`;
                }
                i++;
            }
            this.popout.setDisplayValue(html);

            i = 0;
            let self = this;
            for(let token of this.label_tokens) {
                if(token) {
                    let token_close_element = document.getElementById(this.brick_id+"$close$"+i);
                    if(token_close_element) {
                        if(!token_close_element.getAttribute("has-close")) {
                            token_close_element.addEventListener("click", function(e: any) {
                                let s = e.target.id.split("$");
                                let ti = Number(s[s.length-1]);
                                if(self.label_tokens != self.values) {
                                    self.label_tokens.splice(ti, 1);
                                }
                                if(self.value_tokens != self.values) {
                                    self.value_tokens.splice(ti, 1);
                                }
                                self.values.splice(ti, 1);
                                self.setValue(self.values, true);
                                e.stopPropagation();
                            });

                            token_close_element.setAttribute("has-close", "true");
                        }
                    }
                }
                i++;
            }
        }

        if(emit) {
            this.cb_emit({ "@" : this.value_tokens, "@string": this.value_tokens.join(this.cfg.output_separator || ","), "@option_objects" : this.values });
        }
         
    }

    cb_initial_cement(cements: { [child_idx: number]: any }) {
        

        if(this.blueprint.contains) {
            let innerHTML = "";
            let  i = 0;
            for(let sub of this.blueprint.contains) {
                let cement = cements[i];

                let classes = '';
                if(cement && cement.style_classes) {
                    for(let cl in cement.style_classes) {
                        classes += " " + cement.style_classes[cl];
                    }
                }

                if(cement && cement.hidden) {
                    classes += classes ? " hidden" : "hidden";
                }
                let brick = CBWebUtil.BrickHtml(sub, this, i);
                innerHTML += brick;

                i++;
            }

            let container = document.getElementById(`${this.brick_id}$popout`);
            if(container) {
                container.innerHTML = innerHTML;
            }
        }
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): any {        
        if(status == "focus") {
            let input = document.getElementById(`${this.brick_id}`);
            if(input) {
                (<HTMLInputElement>input).focus();
                return true;
            }
        }

    }

    cb_validate(): boolean {
        let validates = true;
        let validation_message = "";
        if(this.validation) {
            let input = document.getElementById(`${this.brick_id}$select`);
            if(input) {

                if(this.validation.required && this.value_tokens.length == 0) {
                    validates = false;
                    validation_message = (this.cfg.label || this.cfg.placeholder || "Value") + " is a required field.";
                }
                    
                let validation_message_container = document.getElementById(`${this.brick_id}&validation`);

                if(validates) {
                    input.classList.remove("cc-tokenfield-validation-failed");

                    if(validation_message_container) {
                        validation_message_container.style.display = "none";
                        validation_message_container.innerHTML = "";
                    }
                }
                else {
                    input.classList.add("cc-tokenfield-validation-failed");

                    if(validation_message_container) {
                        validation_message_container.innerHTML = validation_message;
                        validation_message_container.style.display = "block";
                    }
                }
            }
            
        }
        return validates;
    }

    cb_snapshot() {}
}
