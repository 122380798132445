import { codebricks_format } from "../../codebricks-runtime/CBFormatting";
import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { cb_pager } from "../controls/cb_pager";
import { PivotHeader, PivotTable, PivotTreeNode, PTableRow, TreeData } from "../../shared-funcs/PivotTable";
import Sugar from "sugar";
import { CBWebUtil } from "../controls/cb_web_util";

export class cd_pivot_table_webcomponent extends HTMLElement {
    ci: web_cd_pivot_table | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_cd_pivot_table(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('cd-pivot-table', cd_pivot_table_webcomponent);

// interface PivotTreeNode {
//     col: string,
//     expanded: boolean,
//     childs: PivotTreeNode[],
//     parent: PivotTreeNode | null,
//     rowspan: number,
//     value: string
// }

export class web_cd_pivot_table extends CodeBrick {

    element: HTMLElement;
    data = [];
    page = 0;
    page_size = 8;
    pages = 1; 
    cfg:any;
    treedata = null as TreeData | null;
    rows: PTableRow[] | undefined;
    cols: { column: string, format: string}[] | undefined;
    dataCols: PTableRow[] | undefined;
    numrows = 0;
    start_row = 0;
    end_row = 20;
    initialized = false;

    dc_data = [] as any[];
    idx = 0;
    prev_data: any;

    pager: cb_pager;

    expander_on_single_childs = 0;

    cements = {} as { [child_idx: number]: any };


    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
        //let shadowRoot = this.attachShadow({ mode: 'open' });

        this.cfg = this.blueprint.ins ? this.blueprint.ins.cfg : {};
        this.cfg.table = this.cfg.table || {};

        //let search = `<div class="cd-table-search-container hidden" id="${this.brick_id}$search_container"><input type="text" spellcheck="false" id="${this.brick_id+"$search"}" class="cd-table-search" /><span class="cd-table-search-close" id="${this.brick_id+"$search_close"}"></span></div>`;
        let search = '';
        if(!this.cfg.table || (this.cfg.table && !this.cfg.table.search) || (this.cfg.table && this.cfg.table.search)) {
            search = `<div class="cd-table-search-container${(this.cfg.table.search.hidden || (this.context.platform == "file" && !this.cfg.table.search.value)) ? " hidden" : ""}"><input type="text" spellcheck="false" id="${this.brick_id+"$search"}" placeholder="${(this.cfg.table && this.cfg.table.search && this.cfg.table.search.label) ? this.cfg.table.search.label : 'Search'}" class="cd-table-search" /><span class="cd-table-search-close" id="${this.brick_id+"$search_close"}"></span></div>`;
        }

        let title = `<h2 class="cd-table-title hidden" id="${this.brick_id+"$title"}"></h2>`;

        // let pager = '';
        // let entries = '';
        // if(!this.cfg.table || !this.cfg.table.no_pager) {
        //     pager = `<div class="cd-table-pager"><span id="${this.brick_id+"$pgdn"}" class="cd-table-pg">&lt;</span><span id="${this.brick_id+"$pg"}">1</span><span id="${this.brick_id+"$pgup"}" class="cd-table-pg">&gt;</span></div>`;
        //     entries = `<div id="${this.brick_id+"$counts"}" class="cd-table-counts"></div>`;
        // }


        //let html = `<div style="width:100%"><div id="${this.brick_id}" ><div class="cd-table-header">` + search + title + `</div><div class="cd-table-container"><table id="${this.brick_id}$table"></table></div><div id="${this.brick_id+"$footer"}" class="cd-table-footer"></div></div><div id="${this.brick_id}$loader" class="loader"></div></div>`;
        let html = `<div class="cd-table-header"><div><div id="${this.brick_id+"$header"}" class="hidden"></div>` + search + "</div>" + title + `</div>
                    <div id="${this.brick_id}$container" class="cd-table-container">`;  
        html +=    `<table id="${this.brick_id}" class="cd-table"></table>`;
        html +=    `<div id="${this.brick_id}$empty" class="cd-table-empty"></div></div>
                    <div id="${this.brick_id+"$footer"}" class="cd-table-footer ${(this.cfg.table.no_pager || this.context.platform == "file")  ? "hidden" : ""}"></div>`;



        this.element.innerHTML = html;

        let self = this;
        this.pager = new cb_pager(this.brick_id+"$footer", {},  
            async function(value:any) {
                self.cb_pager_evt(value);
            }
        );

        this.init_cement();
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {
        //console.log("CodeBricksTable "+this.brick_id+" cb_event "+input+" "+JSON.stringify(cfg));

        if(input == "cfg") {

            if(this.data && cfg.data && this.data.length != cfg.data.length) {
                this.page = 0;
            }

            this.data = cfg.data || [];
            this.dc_data = this.data;

            this.cfg = cfg;

            if(this.cfg.table && this.cfg.table.page_size !== undefined) {
                this.page_size = this.cfg.table.page_size || 12;
            }

            let search_container = document.getElementById(this.brick_id+"$search_container");
            if(search_container) {
                let top = document.getElementById(this.brick_id);
                if((!this.cfg.table || (this.cfg.table && !this.cfg.table.search) || (this.cfg.table && this.cfg.table.search && !this.cfg.table.search.hidden)) && this.context.platform != "file") {
                    search_container.classList.remove("hidden");

                    if(top) {
                        top.classList.remove("cd-table-nosearch");
                    }
                }
                else {
                    search_container.classList.add("hidden");
                    
                    if(top) {
                        top.classList.add("cd-table-nosearch");
                    }
                }
            }
          
            let title = document.getElementById(this.brick_id+"$title");
            if(title) {
                if(this.cfg.table && this.cfg.table.title) {
                    title.innerHTML = this.cfg.table.title;
                    title.classList.remove("hidden");
                }
                else {
                    title.classList.add("hidden");
                }
            }

            this.rows = [] as PTableRow[];
            if(cfg.rows) {
                for(let r = 0 ;r < cfg.rows.length; r++) {
                    let cfg_row = cfg.rows[r];
                    this.rows.push({ col: cfg_row.column, rename: cfg_row.rename, format: cfg_row.format || '', val: '' });
                }
            }

            this.cols = [] as { column: string, format: string}[];
            if(cfg.columns) {
                for(let c = 0 ;c < cfg.columns.length; c++) {
                    let cfg_col = cfg.columns[c];
                    this.cols.push(cfg_col);
                }
            } 

            this.dataCols = [] as PTableRow[];
            if(cfg.data_columns) {
                for(let dc = 0 ; dc < cfg.data_columns.length; dc++) {
                    let cfg_col = cfg.data_columns[dc];
                    this.dataCols.push({ col: cfg_col.column, rename: cfg_col.rename, format: cfg_col.format || '', val: '' });
                }
            }

            this.page = 0;
            this.start_row = this.page*this.page_size;
            this.end_row = (this.page+1)*this.page_size;

            let footer =  document.getElementById(this.brick_id+"$footer");
            if((cfg.table && cfg.table.no_pager) || this.context.platform == "file") {
                this.end_row = Number.MAX_VALUE;
                if(footer) {
                    footer.classList.add("hidden");
                }
            }
            else {
                if(footer) {
                    footer.classList.remove("hidden");
                }
            }
            
            await this.render_data(cfg.data, this.rows, this.cols, this.dataCols);

            let search_element = document.getElementById(this.brick_id+"$search") as HTMLInputElement;
            if(!this.initialized) {
                let self = this;
                
                if(search_element) {
                    search_element.addEventListener('input', async function() {
                        if(search_element) {
                            await self.search_changed();
                        }
                    });
                    let search_term = (<HTMLInputElement>search_element).value;

                    let search_close = document.getElementById(this.brick_id+"$search_close");
                    if(search_close) {
                        search_close.style.display = search_term == "" ? "none" : "inline-block";
        
                        if(!this.initialized) {
                            let self = this;
                            search_close.addEventListener("click", async function() {
                                let search = document.getElementById(self.brick_id+"$search");
                                let search_term = (<HTMLInputElement>search).value;
                                if(search_term) {
                                    (<HTMLInputElement>search).value = "";
                                    await self.search_changed();
                                }
                            });
                        }
                    }
                }
            }

            if(search_element) {
                search_element.placeholder = (this.cfg.table && this.cfg.table.search && this.cfg.table.search.label) ? this.cfg.table.search.label : 'Search';
            }

            this.initialized = true;
            //return { "@" : this.dc_data };
        }
        else if(input == "hide") {
            let container = document.getElementById(this.brick_id);

            if(container) {
                if(cfg) {
                    container.classList.add("hidden");
                }
                else {
                    container.classList.remove("hidden");
                }
            }
        }
    }

    async search_changed() {
        let search_element = document.getElementById(this.brick_id+"$search") as HTMLInputElement;
        this.start_row = 0;
        if(this.cfg.table && this.cfg.table.no_pager) {
        }
        else {
            this.end_row = this.start_row + this.page_size;
        }
        let expand_levels = 999;
        if(this.cfg.table.expand_levels !== undefined && this.cfg.table.expand_levels !== null && !isNaN(this.cfg.table.expand_levels )) {
            expand_levels = Number(this.cfg.table.expand_levels);
        }
        let tree = PivotTable.dataToTreeP(this.data, this.rows as PTableRow[], this.cols as { column: string, format: string}[], this.dataCols as PTableRow[], expand_levels, search_element.value as string, false, this.context.composition_runners[this.cid].compos?.system_options || {}, false, this.cfg && this.cfg.table && this.cfg.table.dont_sort_nodes);
        this.treedata = tree;
        await this.drawTable();
        this.setEventHandlers();
        let search_term = search_element.value;
        if(search_term) {
            search_element.classList.add("cd-table-search-active");
        }
        else {
            search_element.classList.remove("cd-table-search-active");
        }

        let search_close = document.getElementById(this.brick_id+"$search_close");
        if(search_close) {
            search_close.style.display = search_term == "" ? "none" : "inline-block";
        }
    }

    async render_data(data: any, rows: PTableRow[], cols: { column: string, format: string}[], dataCols: PTableRow[]) {

        let nullstr = null as string | null;
        let expand_levels = 999;
        if(this.cfg.table.expand_levels !== undefined && this.cfg.table.expand_levels !== null && !isNaN(this.cfg.table.expand_levels )) {
            expand_levels = Number(this.cfg.table.expand_levels);
        }
        let tree = PivotTable.dataToTreeP(this.data, rows, cols, dataCols, expand_levels, nullstr as string, this.cfg && this.cfg.table && this.cfg.table.dont_order_data_cols, this.context.composition_runners[this.cid].compos?.system_options || {}, false, this.cfg && this.cfg.table && this.cfg.table.dont_sort_nodes);
        this.treedata = tree;

        await this.drawTable();

        this.setEventHandlers();

        //this.ToTree(tree, rows, 0, 0, rows.length, null);

        //console.log("Tree: "+tree);     
    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {
        //console.log("cd-table "+this.brick_id+" cb_initial_cement "+JSON.stringify(cements));
        this.cements = cements;
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {

        //console.log("cd-table "+this.brick_id+" cb_update_cement "+child_idx + " cement "+JSON.stringify(cement));

        //this.cements[child_idx] = cement;

        let dc_root = (this.dc || "") + ("--" + this.blueprint.name);

        let child_dc = dc_root + "--" + row_idx;

        let child = this.element.querySelector('[dc="'+child_dc+'"][idx="'+child_idx+'"][container_id="'+this.brick_id+'"]');
        if(child) {
            if(cement.hidden) {
                child.classList.add("hidden");
            }
            else {
                child.classList.remove("hidden");
            }
        }
    }
    cb_status(status: string): void {
        if(status == "loading") {

            let table = document.getElementById(this.brick_id);
            if(table) {
                table.innerHTML = `<tr><td colspan="100"><div class="loader loader-loading"></div></tr>`
            }

            let footer =  document.getElementById(this.brick_id+"$footer");
            if(footer) {
                footer.style.visibility = 'hidden';
            }
        }
        else {
            let footer =  document.getElementById(this.brick_id+"$footer");
            if(footer) {
                footer.style.visibility = 'visible';
            }
        }
    }

    async drawTable() {
        this.flush_dynamic();
        
        this.numrows = 0;
        let table = document.getElementById(this.brick_id);
        if(this.treedata && this.rows && this.treedata.pivotHeaders) {

            let data_total_column = false;
            if(this.cfg.data_aggregate_columns && this.cfg.data_aggregate_columns.length > 0) {
                for(let dac of this.cfg.data_aggregate_columns) {
                    if(dac.type == "Sum") {
                        data_total_column = true;
                    }
                }
            }

            let no_totals = this.cfg && this.cfg.table && this.cfg.table.no_totals;
            let no_pivot_col_names = this.cfg && this.cfg.table && this.cfg.table.no_pivot_col_names;
            
            let html = this.treeToHtmlTableP(this.treedata.tree, this.rows, this.treedata.pivotHeaders, !no_pivot_col_names, data_total_column, false, this.page_size, no_totals, false, (this.cfg && this.cfg.table && this.cfg.table.expander_on_single_childs) ? this.cfg.table.expander_on_single_childs : 0);
            
            if(table) {
                //This must be first
                table.innerHTML = html;
            }

            await this.send_dynamic_initialisation_events(this.dc_data);

            this.pages = Math.ceil(this.numrows / this.page_size);


            await this.pager.setPages(this.numrows, this.page_size, this.page);
            
            //console.log("numrows "+this.numrows);

            // let pgdn = document.getElementById(this.brick_id+"$pgdn");
            // if(pgdn) {
            //     if(this.page == 0) {
            //         pgdn.style.display = 'none';
            //     }
            //     else {
            //         pgdn.style.display = 'inline';
            //     }
            // }
            // let pgup = document.getElementById(this.brick_id+"$pgup");
            // if(pgup) {
            //     if(this.page + 1 < this.pages) {
            //         pgup.style.display = 'inline';
            //     }
            //     else {
            //         pgup.style.display = 'none';
            //     }
            // }

        }
        else {
            if(table) {
                table.innerHTML = this.blueprint.ins.cfg.empty_table_message || "No entries.";              
            }
            await this.pager.setPages(0, this.page_size, 0);
        }
    }

    // ColName(column: string, rename: string) : string {
    //     if(rename !== null && rename !== undefined && JSON.stringify(rename) != "{}") {
    //         return rename;
    //     }
    //     return Prettyfy(column);
    // }

    DrawPager() {
        
    }

    setEventHandlers() {

        let self = this;

        var drill_elements = document.getElementsByClassName("cd-pivot-table-drill-"+this.ci_idx);
        for (var i = 0; i < drill_elements.length; i++) {
            drill_elements[i].addEventListener('click', 
            async function(this: any) {
                var branch = this.getAttribute("data-branch");
                var s = branch.split('d');
                if(self.treedata) {
                    var node = self.treedata.tree;
                    for(var i = 0; i < s.length; i++) {
                        node = node.children[s[i]];
                    }
            
                    //console.log("branch="+branch+" node="+node.val+": open="+node.open);
            
                    if(node) {
                        node.open = !node.open;
                        //var page = $('#' + this.uiid).DataTable().page();
                
                        await self.drawTable();
                        self.setEventHandlers();
                    }
            
                    //$('#' + this.uiid).DataTable().page(page).draw('page');
                    //this.flatViewButtons();
                }
            }
            , false);
        }

        // let pgdn = document.getElementById(this.brick_id+"$pgdn");
        // if(pgdn) {
        //     pgdn.onclick = async function () {
        //         if(self.page > 0) {
        //             self.page--;
        //             self.start_row = self.page*self.page_size;
        //             self.end_row = (self.page+1)*self.page_size;
                    
        //             await self.drawTable();
        //             self.setEventHandlers();

        //             let pg = document.getElementById(`${self.brick_id+"$pg"}`);
        //             if(pg) {
        //                 pg.innerHTML = String(self.page + 1);
        //             }
        //         }
        //     }
        // }
        // let pgup = document.getElementById(this.brick_id+"$pgup");
        // if(pgup) {
        //     pgup.onclick = async function () {
        //         if(self.page + 1 < self.pages) {
        //             self.page++;
        //             self.start_row = self.page*self.page_size;
        //             self.end_row = (self.page+1)*self.page_size;

        //             await self.drawTable();
        //             self.setEventHandlers();

        //             let pg = document.getElementById(`${self.brick_id+"$pg"}`);
        //             if(pg) {
        //                 pg.innerHTML = String(self.page + 1);
        //             }
        //         }
        
        //     }
        // }

    }

    async cb_pager_evt(goto_page: number) {
        this.page = goto_page;
        this.start_row = this.page*this.page_size;
        this.end_row = (this.page+1)*this.page_size;

        await this.drawTable();
        this.setEventHandlers();
    }


    treeToHtmlTableP(root: PivotTreeNode, rows: PTableRow[], pivotHeaders: PivotHeader[], ShowTopHeader: boolean, showDataTotalCol: boolean, showDataTrend: boolean, rowsPerPage: number, NoTotals: boolean, flatTable: boolean, ExpandSingleChilds: number) {
        var ncs = 3;

        if(!ShowTopHeader) {
            ncs = 2;
        }

        var row2empty = true;
        for(var ph = 0; ph < pivotHeaders.length; ph++) {
            if(pivotHeaders[ph].cc) {
                row2empty = false;
                break;
            }
        }

        if(row2empty) {
            ncs--;
        }

        var totalthclass = NoTotals ? '' : 'top-total-th';

        root.atrow = 0;
        var html = '<thead><tr>';
        let has_data_cols = this.cfg.data_columns && this.cfg.data_columns.length > 0;
        for(var r = 0; r < root.rowskeys.length; r++) {
            var grandtotal = (NoTotals || !has_data_cols) ? '' : '<div class="viz_top_total">'+ (r == 0 ? '&nbsp;' : '&nbsp;') +'</div>'
            html += '<th rowspan="'+ncs+'" class="'+totalthclass+'">' + (root.rowskeys[r].rename || root.rowskeys[r].col) + grandtotal +'</th>';
        }
    
        var totalTotal = 0;
        if(showDataTotalCol) {
            for(var ph = 0; ph < pivotHeaders.length; ph++) {
                totalTotal += root.vals[pivotHeaders[ph].col+"~"+pivotHeaders[ph].cc+"~"+pivotHeaders[ph].dc];
            }
        }

        if(ShowTopHeader) {
            html += this.csths(pivotHeaders, 'col');
            html += this.totalAndTrendHeaders(ncs, showDataTotalCol, showDataTrend, totalTotal, flatTable, '');
            html += '</tr><tr>';
        }
        if(!row2empty) {
            html += this.csths(pivotHeaders, 'cc');
            if(!ShowTopHeader) {
                html += this.totalAndTrendHeaders(ncs, showDataTotalCol, showDataTrend, totalTotal, flatTable, pivotHeaders[0].frmt as string);
            }
            html += '</tr><tr>';
        }
        //html += csths(pivotHeaders, 'dc');
        for(var ph = 0; ph < pivotHeaders.length; ph++) {
            if(NoTotals) {
                if (flatTable) {
                    html += "<th>"+(pivotHeaders[ph].ren || pivotHeaders[ph].dc)+"</th>";
                }
                else {
                    html += "<th>"+(pivotHeaders[ph].ren || pivotHeaders[ph].dc)+"</th>";
                }
            }
            else {
                var totalstr = this.vizformat(this.limitdecimals(root.vals[pivotHeaders[ph].col + "~" + pivotHeaders[ph].cc + "~" + pivotHeaders[ph].dc]), pivotHeaders[ph].frmt as string);


                var totalstrlen = totalstr.toString().length * 1.5;
                var headingstr = (pivotHeaders[ph].ren || pivotHeaders[ph].dc).replace(" ", "&nbsp;");
                var headingstrlen = headingstr.length;
                if(totalstrlen > headingstrlen) {
                    for(var x = 0; x < (totalstrlen - headingstrlen) + 2; x++) {
                        headingstr += "&nbsp;";
                    } 
                }
                if (flatTable) {
                    html += "<th class='top-total-th'>"+headingstr+"<div class='viz_top_total'>"+totalstr+"&nbsp;</div></th>";
                }
                else {
                    html += "<th class='top-total-th sorting_'>"+headingstr+"<div class='viz_top_total'>"+totalstr+"&nbsp;</div></th>";
                }
            }
        }

        if(!ShowTopHeader && row2empty) {
            html += this.totalAndTrendHeaders(ncs, showDataTotalCol, showDataTrend, totalTotal, flatTable, '');
        }
        
        html += '</tr></thead><tbody>';
        var bp = "";
        for(var n = 0; n < root.children.length; n++) {
            html += this.drawRowsP(root, root.children[n], pivotHeaders, 0, n + '', showDataTotalCol, showDataTrend, rowsPerPage, flatTable, ExpandSingleChilds);
        }
        html += "</tbody>";
        return html
    }

    totalAndTrendHeaders(ncs: number, showDataTotalCol: boolean, showDataTrend: boolean, totalTotal: number, flatTable: boolean, frmt: string) {
        var ret = "";
        if(showDataTotalCol) {
            if (flatTable) {
                ret += '<th rowspan="' + ncs + '"  class="top-total-th">Total<div class="viz_top_total">' + this.vizformat(this.limitdecimals(totalTotal), frmt) + '</div></th>';
            }
            else {
                ret += '<th onclick="'+this.brick_id+'.flatView()" rowspan="' + ncs + '"  class="top-total-th">Total<div class="viz_top_total">' + this.vizformat(this.limitdecimals(totalTotal), frmt) + '</div></th>';
            }
        }

        if(showDataTrend) {
            ret += '<th rowspan="'+ncs+'"  class="top-total-th">Trend<div class="viz_top_total">&nbsp;</div></th>';
        }
        return ret;
    }

    csths(pivotHeaders: PivotHeader[], valname: string) {
        var ret = "";
        var cs = [];
        for(var ph = 0; ph < pivotHeaders.length; ph++) {
            if(ph == 0 || cs[cs.length -1].key != (pivotHeaders[ph] as any)[valname]) { 
                cs.push({ key: (pivotHeaders[ph] as any)[valname], s: 1});
            }
            else {
                cs[cs.length -1].s++;
            }
        }
        for(var c = 0; c < cs.length; c++) {
            var val = cs[c].key || "";
            var uspos = val.toString().indexOf('_');
            if(uspos != -1) {
                val = val.substring(uspos + 1);
            }

            ret += "<th colspan='"+cs[c].s+"'>"+val+"</th>";
        }
        return ret;
    }

    drawRowsP(root: PivotTreeNode, node: PivotTreeNode, pivotHeaders: PivotHeader[], at: number, bp: string, showDataTotalCol: boolean, showDataTrend: boolean, rowsPerPage: number, flatTable: boolean, ExpandSingleChilds: number) {
        var at1 = 0;
        var html = "<tr>";
        var rows = root.rowskeys;
        var leafVals = "";

        while(at1 < rows.length) {

            let is_continuation = (at1 < at && (root.atrow % rowsPerPage) == 0);

            let child = "";
            let val = node.tval as any;

            if(at1 == at || is_continuation) {
                if(node.format) {
                    val = this.vizformat(val, node.format);
                }
                let column = rows[at1].col;

                child = this.makeChild(node.idx, column, val);
            }
            
            if(at1 == at) {

                if(node.children) {
                    if(node.children.length > 1 || (ExpandSingleChilds > at && (node.children.length == 1))) {
                        if(flatTable) {
                            //html += "<td>" + node.tval + "</td>";
                            leafVals += "<td>" + (child || val) + "</td>";
                        }
                        else {
                            //let before_icon = ""
                            //let after_icon = child || val;
                            if(is_continuation) {
                                html += "<td class='pt-continue'>..." + (child || val) + "</td>";
                            }
                            else {
                                html += "<td>" + (child || val) + "</td>";
                            }
                        }
                        rows[at1].val = val;
                    }
                    else {
                        if(flatTable) {
                            leafVals += "<td>" + (child || val)+ "</td>";
                        }
                        else {
                            if(is_continuation) {
                                html += "<td class='pt-continue'>..." + (child || val)+ "</td>";
                            }
                            else {
                                html += "<td>" + (child || val)+ "</td>";
                            }
                        }
                        rows[at1].val = val;
                        node = node.children[0];
                        bp = bp + "d" + 0;
                        at++;
                    }
                }
                else {
                    html += leafVals + "<td>" + (child || val) + "</td>";
                }
            }
            else {
                if(flatTable) {
                    if(!node.children) {
                        html += leafVals + "<td>" + rows[at1].val + "</td>";
                    }
                    else if(node.children.length == 1) {
                        leafVals += "<td>" + rows[at1].val + "</td>";
                    }
                }
                else if(at1 < at) {
                    if((root.atrow % rowsPerPage) == 0) {
                        html += "<td class='pt-continue'>..." + (child || rows[at1].val) + "</td>";
                    }
                    else {
                        html += "<td class='pt-empty'></td>";
                    }
                }
                else if(at1 == at + 1) {
                    html += "<td class='pt-expand'><span></span><span data-branch='" + bp + "' "+(node.open ? (" class='cd-pivot-table-drill cd-pivot-table-drill-closed cd-pivot-table-drill-"+this.ci_idx+" '></span> ") : (" class='cd-pivot-table-drill cd-pivot-table-drill-open cd-pivot-table-drill-"+this.ci_idx+" '></span> ")) + "</td>";
                }
                else {
                    html += "<td class='pt-through'></td>";
                }
            }
            at1++;
        }

        var total = 0;
        var sumclass="class='td-num'";
        for(var ph = 0; ph < pivotHeaders.length; ph++) {
            
            if(node.children) {
                sumclass="class='pt-sum'";
                if(flatTable) {
                    continue;
                }
            }

            let child = "";
            let val = this.vizformat(node.vals[pivotHeaders[ph].col+"~"+pivotHeaders[ph].cc+"~"+pivotHeaders[ph].dc], pivotHeaders[ph].frmt as string);
            let column = pivotHeaders[ph].col;

            child = this.makeChild(node.idx + ph + 1, column, val);
            html += '<td '+sumclass+'>'+ (child || val)+ '</td>';

            if(showDataTotalCol) {
                total += node.vals[pivotHeaders[ph].col+"~"+pivotHeaders[ph].cc+"~"+pivotHeaders[ph].dc];
            }
 
        }
        
        if(showDataTotalCol && html != "<tr>") {
            html += "<td " + sumclass + ">" + this.vizformat(this.limitdecimals(total), pivotHeaders[0].frmt as string) + "</td>";
        }

        html += "</tr>";
        if(html == "<tr></tr>") {
            html = "";
        }
        root.atrow++;
        this.numrows++;

        if(this.numrows <= this.start_row || this.numrows > this.end_row) { 
            html =  '';
        }
        
        if(node.children && (node.open || flatTable)) {
            for(var c = 0; c < node.children.length; c++) {
                html += this.drawRowsP(root, node.children[c], pivotHeaders, at + 1, bp + "d" + c, showDataTotalCol, showDataTrend, rowsPerPage, flatTable, ExpandSingleChilds);
            }
        }
        
        return html;
    }

    makeChild(dc_idx: number, column: string, val: string) {
        let child = "";
        if(this.blueprint.contains) {

            let dc_root = (this.dc || "") + ("--" + this.blueprint.name);

            let child_dc = dc_root + "--" + dc_idx;

            let i = 0;
            for(let sub of this.blueprint.contains) {

                let cement = this.cements[i] || sub.cement;

                if(cement && (cement.column == column)) {//} || sub.cement.column === col_idx)) {
                    if(cement.value == "before") {
                        child += val;
                    }

                    //this.dc_data[row_idx] = this.dc_data[row_idx] || {};
                    //this.dc_data[row_idx][column] = node.tval;

                    let hidden = (cement && cement.hidden) ? ` class="hidden"` : "";
                    
                    let brick = CBWebUtil.BrickHtml(sub, this, i, child_dc);
                    child += brick;

                    //this.context.cb_indata[sub.brick.name + child_dc] = Object.create(this.context.cb_indata[sub.brick.name + this.dc] || {});
                    //ObjectDeepMerge(this.context.cb_indata[sub.brick.name + child_dc], row);

                    if(sub.cement.value == "after") {
                        child += val;
                    }
                }
                i++;
            }
        }
        return child;
    }

    limitdecimals(val: number) {
        // if (typeof val == 'undefined' || val === null) {
        //     return "";
        // }
        // if (isNaN(val)) {
        //     return val;
        // }
        // if(val.toString().indexOf('.') == -1) {
        //     return val;
        // }
        // return  Math.round( val * 1e2 ) / 1e2;
        return val;
    }

    vizformat(num: any, format: string) {
        return codebricks_format(num, format, this.context.composition_runners[this.cid].compos?.system_options).value;
    }

    cb_snapshot() {
        let snapshot = {} as any;
        let search = document.getElementById(this.brick_id+"$search");
        if(search) {
            let search_term = (<HTMLInputElement>search).value;
            if(search_term) {
                Sugar.Object.set(snapshot, "cfg.table.search.value", search_term);
            }
        }
        return snapshot;
    }

}


